<template>
   <form
      @submit.prevent="submitForm"
      class="registration-form form"
      action="#"
      id="registration-form"
      novalidate
   >
      <div class="registration-form__inner">
         <app-field
            v-model:field="field.value"
            v-for="field in fields"
            :key="field.name"
            :is-form-submission="isFormSubmission"
            :is-displayed-error="isDisplayedError"
            :settings="field"
            @result-checking="saveResultChecking"
            @form-reset="resetForm"
         />
      </div>
   </form>
</template>

<script>
   import AppField from '@/components/templates/FormFields/AppField';

   export default {
      name: 'ModalRegistration',

      components: {
         AppField
      },

      emits: {
         'form-data': (value) => typeof value === 'object'
      },

      inject: ['openModal'],

      data() {
         return {
            isFormSubmission: false,
            isDisplayedError: false,
            fieldsValidationResults: [],

            fields: [
               {
                  name: 'Email',
                  type: 'email',
                  validationType: 'Email',
                  placeholder: 'E-mail'
               },
               {
                  name: 'password',
                  type: 'password',
                  validationType: 'Password',
                  placeholder: 'Пароль',
                  params: { minLength: 8, maxLength: 16 }
               },
               {
                  name: 'passConfirmation',
                  type: 'password',
                  validationType: 'PassConfirmation',
                  placeholder: 'Повторите пароль'
               }
            ],

            modalSettings: {
               name: 'ModalNotification',
               iconName: 'warning',
               message: 'Регистрация временно недоступна',
               isActive: true
            }
         };
      },

      activated() {
         this.$emit('form-data', {
            component: 'ModalRegistration',
            form: 'registration-form',
            title: 'Регистрация',
            buttonText: 'Регистрация'
         });
      },

      methods: {
         saveResultChecking(value) {
            const field = this.fieldsValidationResults.find(
               (item) => item.name === value.name && item.type === value.type
            );

            if (field) {
               field.isValid = value.isValid;
            } else {
               this.fieldsValidationResults.push(value);
            }
         },

         resetForm(value) {
            this.isFormSubmission = value;
         },

         submitForm() {
            if (this.isValidFieldValue) {
               this.isFormSubmission = true;
               this.isDisplayedError = false;

               setTimeout(() => {
                  this.openModal(this.modalSettings);
               }, 200);
            } else {
               this.isDisplayedError = true;
            }
         }
      },

      computed: {
         passwordField() {
            return this.fields.find((field) => field.name === 'password').value;
         },

         passConfirmationField() {
            return this.fields.find((field) => field.name === 'passConfirmation').value;
         },

         isValidFieldValue() {
            return (
               this.fieldsValidationResults.length &&
               this.fieldsValidationResults.every((item) => item.isValid)
            );
         }
      },

      watch: {
         passConfirmationField: {
            handler() {
               const passwordField = this.fields.find((field) => field.name === 'passConfirmation');

               passwordField.data = {
                  password: this.passwordField
               };
            },
            immediate: true
         }
      }
   };
</script>
